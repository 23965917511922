body {
  margin: 0;
  padding: 0;
  color: rgba(28, 31, 35, 0.62);
  font-size: 14px;
  font-family: HarmonyOS Sans SC,Helvetica Neue,Helvetica,Arial,Microsoft Yahei,PingFang SC,Hiragino Sans GB,Heiti SC,WenQuanYi Micro Hei,sans-serif;
  overflow: hidden;
}


#faricList::-webkit-scrollbar {
  display: none;
}
#fabricListAttr::-webkit-scrollbar {
  display: none;
}
.hideScroll::-webkit-scrollbar {
  display: none;
}

html,body{
  height: 100%;
  /* color: var(--semi-color-text-0);
    background-color: var( --semi-color-bg-0); */
}

/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'iconfont';  /* Project id 3984272 */
  src: url('//at.alicdn.com/t/c/font_3984272_wxztbhu6ln8.woff2?t=1693013218305') format('woff2'),
       url('//at.alicdn.com/t/c/font_3984272_wxztbhu6ln8.woff?t=1693013218305') format('woff'),
       url('//at.alicdn.com/t/c/font_3984272_wxztbhu6ln8.ttf?t=1693013218305') format('truetype');
}
/* 在线链接服务仅供平台体验和调试使用，平台不承诺服务的稳定性，企业客户需下载字体包自行发布使用并做好备份。 */
@font-face {
  font-family: 'clo-iconfont';  /* Project id 4564910 */
  src: url('//at.alicdn.com/t/c/font_4564910_1y6pweqxixy.woff2?t=1721995313085') format('woff2'),
       url('//at.alicdn.com/t/c/font_4564910_1y6pweqxixy.woff?t=1721995313085') format('woff'),
       url('//at.alicdn.com/t/c/font_4564910_1y6pweqxixy.ttf?t=1721995313085') format('truetype');
}

.iconfont{
  font-family:"iconfont" !important;
  font-size:16px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}
.clo-iconfont{
  font-family:"clo-iconfont" !important;
  font-size:16px;font-style:normal;
  -webkit-font-smoothing: antialiased;
  -webkit-text-stroke-width: 0.2px;
  -moz-osx-font-smoothing: grayscale;
}

.displayAnimate {
  -webkit-transition: 0.3s;
  -moz-transition: 0.3s;
  -ms-transition: 0.3s;
  transition: 0.3s;
}

.semi-tabs-tab-button.semi-tabs-tab-active{
  background: none !important;
}
.sketch-picker {
  background: none !important;
  width: auto !important;
  box-shadow: none !important;
  padding: 0 !important;
}

#rc-editable-input-1,#rc-editable-input-2,#rc-editable-input-3,#rc-editable-input-4,#rc-editable-input-5{
  background: none !important;
  border: none !important;
}
#rc-editable-input-1,#rc-editable-input-2,#rc-editable-input-3,#rc-editable-input-4,#rc-editable-input-5:focus{
  border: none !important;
  outline: none !important;
}

.semi-tabs-bar-button.semi-tabs-bar-top .semi-tabs-tab:not(:last-of-type){
  margin-right: 0 !important;
}

.semi-tabs-tab-button.semi-tabs-tab{
  width: 33%;
  float: left;
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

/* 旋转动画 指定class为trun即可使用*/
.turn {
  animation: turn 10s linear infinite;
}

@keyframes turn {
  0% {
      transform: rotate(0deg);
  }

  20% {
      transform: rotate(72deg);
  }

  40% {
      transform: rotate(144deg);
  }

  60% {
      transform: rotate(216deg);
  }

  80% {
      transform: rotate(288deg);
  }

  100% {
      transform: rotate(360deg);
  }
}

.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div { /* change div to reference your elements you put in <Masonry> */
  /* background: grey; */
  margin-bottom: 30px;
}

.vip-text {
  font-size: 14px;
  font-weight: bold;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #c9463d; /* 红色 */
  background-color: #fff; /* 白色 */
  padding: 10px; /* 文字与边框的间距 */
  border: 2px solid #c9463d;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2); /* 模糊阴影 */
}

.vip-text:before {
  content: "VIP";
  color: #fff; /* 白色 */
  background-color: #c9463d; /* 红色 */
  padding: 2px 8px;
  border-radius: 4px;
  margin-right: 10px; /* 与文字的间距 */
}

.FIE_crop-tool{
  gap: 0 !important;
  width: 80px;
}
.FIE_crop-tool-label{
  margin-left: 6px;
}

#top_describe{
  position: absolute;
  top: 100px;
  left: calc(50% + 160px);
  width: 130px;
  height: 50px;
  z-index: 100;
  display:block;
  font-size: 16px;
  line-height: 1; 
  white-space: pre-wrap;
  word-break: break-all;
  text-align: left;
}
