.box{
    position: fixed;
    width: 40px;
    left: 15px;
    top: 60px;
    bottom: 0;
    margin: auto;
}
.boxItem{
    color: #333333;
    text-align: center;
    cursor: pointer;
    margin: 14px 0;
}
/* .boxItem:hover .boxItemIconBox i{
    color: #0064fa;
}

.boxItem:hover .boxItemIconBox{
    color: #0064fa;
} */

.boxItemIconBox{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
}
.boxItemIconBox i{
    font-size: 22px;
}
.boxItemTxt{
    font-size: 12px;
}
