.box{
    position: fixed;
    /* width: 40px; */
    right: 10px;
    top: 60px;
    bottom: 0;
    margin: auto;
}
.boxItem{
    color: #333333;
    text-align: center;
    cursor: pointer;
    /* margin: 14px 0; */
}
.boxItem:active .boxItemIconBox{
    background: #333333;
}

.boxItem:active .boxItemIconBox i{
    color: #ffffff;
}

.boxItemIconBox{
    width: 30px;
    height: 30px;
    border-radius: 5px;
    /* background: #000000; */
    display: flex;
    align-items: center;
    justify-content: center;
}
.boxItemIconBox i{
    font-size: 12px;
}
.boxItemTxt{
    font-size: 12px;
}
