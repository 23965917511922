.head {
  width: 100%;
  height: 60px;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  /* border-bottom: 1px solid #ddd; */
  position: fixed;
  left: 0;
  top: 0;
  /* background: #000000; */
}
.headMid {
  display: flex;
  font-size: 12px;
  height: 100%;
  align-items: center;
}
.headMid i {
  font-size: 18px;
  margin-top: 12px;
  display: inline-block;
  margin-bottom: 3px;
}
.headMidItem {
  margin: 0 6px;
  height: 100%;
  color: #333333;
  cursor: pointer;
  padding: 0 8px;
  text-align: center;
}
.headMidItem > div {
  text-align: center;
}

.headMidItem:active {
  color: #0064fa;
}
.placeOrder {
  padding: 5px;
  font-size: 12px;
  text-align: center;
  background-color: #f67c65;
  color: #fff;
  margin-right: 10px;
  border-radius: 3px;
}
.FIE_custom-tool-button{
  margin-left: 12px !important;
  background-color: #fff !important;
  color: #37414b !important;
  font-weight: 400 !important;
}
.FIE_custom-tool-button:hover{
  background-color: #2e323817 !important;
}
